// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-brief-js": () => import("./../../../src/pages/brief.js" /* webpackChunkName: "component---src-pages-brief-js" */),
  "component---src-pages-dziekujemy-za-wypelnienie-briefu-js": () => import("./../../../src/pages/dziekujemy-za-wypelnienie-briefu.js" /* webpackChunkName: "component---src-pages-dziekujemy-za-wypelnienie-briefu-js" */),
  "component---src-pages-dziekujemy-za-zgode-js": () => import("./../../../src/pages/dziekujemy-za-zgode.js" /* webpackChunkName: "component---src-pages-dziekujemy-za-zgode-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-projekty-js": () => import("./../../../src/pages/projekty.js" /* webpackChunkName: "component---src-pages-projekty-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-pages-uslugi-js": () => import("./../../../src/pages/uslugi.js" /* webpackChunkName: "component---src-pages-uslugi-js" */)
}

